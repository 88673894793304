/*
 * All MimeType related data in one place where the keys are a common way we refer to file types
 * accept: comma-separated list of all acceptable MimeTypes for given file type
 * display: how we present the MimeType to a user
 * contentType: the singular way we represent the MimeType internally and to the API
 * */

export type MimeTypeConfig = {
  accept: string;
  contentType: Nl.UploadContentType;
  display: string;
};

export const mimeTypeConfigs: Record<string, MimeTypeConfig> = {
  mp3: {
    display: 'mp3',
    accept: 'audio/mp3,audio/mpeg,audio/mpeg3,audio/x-mpeg-3,video/x-mpeg',
    contentType: 'audio/mp3',
  },
  wav: {
    display: 'wav',
    accept: 'audio/wav,audio/wave,audio/x-wav,audio/x-wave',
    contentType: 'audio/wav',
  },
  zip: {
    display: 'zip',
    accept:
      'application/zip,multipart/x-zip,application/x-zip-compressed,application/x-compressed',
    contentType: 'application/zip',
  },
};
