import * as React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { uploadRevisionFile } from 'store/upload';
import UploadDropzone from 'components/Dropzone/UploadDropzone';
import { useFormikContext } from 'formik';
import { isEmpty } from 'lodash';
import { MimeTypeConfig } from 'constants/mimeTypeConfig';

interface OwnProps {
  revisionUuid: string;
  mimeTypeConfig: MimeTypeConfig;
  sourceFile: any;
  type: Nl.SongSourceFileType;
  state?: Nl.SourceFileUploadState;
  formValues: Nl.UploadFormValues;
}

interface DispatchProps {
  onUpload: (file: File, contentType: Nl.UploadContentType) => void;
}

type AllProps = OwnProps & DispatchProps;

const RevisionUploadDropzone: React.FC<AllProps> = ({
  onUpload,
  type,
  state,
  mimeTypeConfig,
  sourceFile,
  formValues,
}) => {
  const { errors } = useFormikContext<Nl.Api.SongRevision>();
  return (
    <UploadDropzone
      accept={mimeTypeConfig.accept}
      displayFileType={mimeTypeConfig.display}
      onDrop={(accepted) => {
        if (accepted.length > 0) {
          onUpload(accepted[0], mimeTypeConfig.contentType);
        }
      }}
      onClear={() => {
        formValues.set(`${formValues.field}.state`, 'init');
      }}
      state={state}
      disabled={!type}
      doneMessage={sourceFile.filename}
      isRequiredError={!isEmpty(errors.source_files)}
    />
  );
};

export default compose(
  connect(
    null,
    (dispatch, { revisionUuid, sourceFile, type, formValues }: OwnProps) => ({
      onUpload: (file: File, contentType: Nl.UploadContentType) => {
        dispatch(
          uploadRevisionFile({
            contentType,
            revisionUuid,
            sourceFileUuid: sourceFile.uuid,
            file,
            type,
            formValues,
          }),
        );
      },
    }),
  ),
)(RevisionUploadDropzone);
